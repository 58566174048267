<template>
  <div>
    <a-modal
      width="1000px"
      v-model="isShow"
      centered
      :mask-closable="false"
      title="项目详情"
    >
      <template slot="footer">
        <a-button
          type="primary"
          @click="closeModal"
        >
          关闭
        </a-button>
      </template>

      <a-spin :spinning="loading">
        <agent-purchase-item-basic-info :data="data" />
      </a-spin>
    </a-modal>
  </div>
</template>

<script>
import { findAgentPurchaseItem } from '@/api/agent_purchase_item'
import AgentPurchaseItemBasicInfo from '@/views/agent_purchase_items/show/BasicInfo'
import { hasPermission } from '@/utils/permission'

export default {
  name: 'ShowAgentPurchaseBill',
  components: {
    AgentPurchaseItemBasicInfo
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      data: {},
      loading: true
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    isShowMenu(menu) {
      return hasPermission(menu)
    },

    fetchData() {
      this.loading = true
      findAgentPurchaseItem(this.id).then((res) => {
        if (res.code === 0) {
          this.data = res.data
        }
        this.loading = false
      })
    },

    closeModal() {
      this.isShow = false
    }
  }
}
</script>

